<template>
  <OrderQuery breadcrumbTitle="Pending" query="?status=WaitPartner" />
</template>

<script>
import OrderQuery from '@/components/order/OrderQuery.vue';

export default {
  name: 'OrderPending',
  components: {
    OrderQuery,
  },
};
</script>

<style></style>
